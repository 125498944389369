body {
  margin: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Seravek, "Gill Sans Nova", Ubuntu, Calibri, "DejaVu Sans", source-sans-pro,
    sans-serif;
}

.lazyload,
.lazypreload {
  visibility: hidden;
}

#spence_button.spence {
  width: 100%;
  display: flex;
  justify-content: center;
}
